import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { SwiperSlide } from 'swiper/react';
import DivisionLogo from '@/components/DivisionLogo';
import Carousel from '@/components/Carousel';
import Link from '@/components/Link';
import { replacer } from '@/helpers/string.js';
import { Routes } from '@/enums/routes.enum.js';
import MetalText from '@/components/MetalText';
import ChevronRightIcon from '@resources/svg/icons/chevron-right.svg';
import { CategoriesProps } from '@/components/Categories';

export interface DivisionsProps extends CategoriesProps {
  divisions: ReadonlyArray<{
    node: Pick<
      GatsbyTypes.SanityDivision,
      'id' | 'key' | 'title' | 'description'
    >;
  }>;
}

const Divisions: React.FC<DivisionsProps> = ({
  divisions,
  className,
  ...any
}) => {
  const { t } = useTranslation();

  return (
    <Carousel
      aria-labelledby="categories-label"
      navigation={true}
      freemode={true}
      sticky={true}
      singularLabel={t('Link')}
      className={`-ml-2xs-fluid ${className ?? ''}`}
      {...any}
    >
      <SwiperSlide className="flex-grow w-2" />
      {divisions.map(({ node }, i) => (
        <SwiperSlide
          key={node.id}
          className={`w-auto self-stretch min-w-min py-2xs px-2xs-fluid ${
            i > 0
              ? 'border-l border-gray-300 dark:border-gray-600 dark:border-opacity-40'
              : ''
          }`}
        >
          <Link
            key={node.id}
            to={replacer(Routes.Division, node)}
            className="block no-underline rounded text-inherit py-base-fluid group px-md-fluid hover:no-underline"
          >
            <DivisionLogo
              division={node.key!}
              aria-hidden="true"
              className="w-auto h-5 text-blue-500 my-sm-fluid dark:text-inherit lg:h-6 xl:h-7 2xl:h-8"
            />
            <span className="sr-only">{node.title}</span>
            <MetalText className="text-sm font-normal transition-all duration-700 transform-gpu max-w-prose-xs mt-sm-fluid py-2xs md:text-base lg:text-lg group-hover:bg-top group-hover:bg-2x 3xl:text-2xl">
              {node.description}
            </MetalText>
            <span
              aria-hidden="true"
              className="flex items-center justify-center w-5 h-5 text-blue-500 transition duration-300 border border-blue-500 rounded-full border-opacity-70 mt-xs-fluid group-hover:bg-blue-500 group-hover:text-white group-hover:border-opacity-100 dark:group-hover:text-blue-900 md:w-6 md:h-6 lg:w-7 lg:h-7"
            >
              <ChevronRightIcon className="w-2 h-2 md:w-3 md:h-3 lg:w-3.5 lg:h-3.5" />
            </span>
          </Link>
        </SwiperSlide>
      ))}
      <SwiperSlide className="flex-grow w-2" />
    </Carousel>
  );
};

export default Divisions;
