import * as React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { findI18nNode } from '@/helpers/sanity.js';
import MasonryGrid from '@/components/MasonryGrid';
import Seo from '@/components/Seo';
import Layout from '@/layouts/default';
import StoryCard from '@/components/StoryCard';
import BlockContent from '@/components/BlockContent';
import BlockContentContainer from '@/components/BlockContentContainer';
import Divisions from '@/components/Divisions';
import VideoOverlayCard from '@/components/VideoOverlayCard';

type IndexPageProps = {
  data: GatsbyTypes.HomePageQuery;
};

const IndexPage: React.FC<IndexPageProps> = ({
  data: { homepage, stories, divisions, allIntroVideos },
}) => {
  const { language } = useI18next();

  let blocks = homepage?._rawBody ?? ([] as Array<any>);
  let primaryBlocks = [];

  if (blocks.length && blocks[0]._type === 'header') {
    primaryBlocks = blocks.slice(0, 1);
    blocks = blocks.slice(1);
  }

  const introVideo =
    (
      findI18nNode(
        allIntroVideos.edges,
        language,
      ) as GatsbyTypes.SanitySiteSettings
    )?.intro_video ?? null;

  const renderGrid = () => {
    let grid = stories.edges.map(({ node }, i) => (
      <StoryCard key={`${i}-${node.id}`} story={node} readingTime={false} />
    ));
    grid.splice(
      5,
      0,
      introVideo ? (
        <VideoOverlayCard
          title={introVideo.title!}
          preview={introVideo.preview}
          mp4={introVideo.video_mp4?.asset?.url}
          webm={introVideo.video_webm?.asset?.url}
        />
      ) : (
        <></>
      ),
    );
    return grid;
  };

  return (
    <Layout>
      <Seo
        title={homepage?.meta?.title ?? 'Ahead of innovation'}
        description={homepage?.meta?.description}
      />
      {primaryBlocks.length > 0 && (
        <BlockContentContainer>
          <BlockContent blocks={primaryBlocks} isOnlyContent={true} />
        </BlockContentContainer>
      )}
      {blocks.length > 1 && (
        <BlockContentContainer>
          <BlockContent blocks={blocks} isOnlyContent={false} />
        </BlockContentContainer>
      )}
      <Divisions divisions={divisions.edges} className="mt-lg-fluid" />
      <MasonryGrid staggered={true}>
        {renderGrid()}
      </MasonryGrid>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query HomePage($language: String!, $divisionKeys: [String!]) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...LocaleStrings
    }
    homepage: sanityPage(
      purpose: { eq: "homepage" }
      i18n_lang: { eq: $language }
    ) {
      title
      meta {
        title
        description
      }
      mainMedia {
        ...SanityCoverFields
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
    stories: allSanityStory(
      filter: { i18n_lang: { eq: $language }, hasParentItem: { eq: false } }
      sort: { fields: priority, order: DESC }
    ) {
      edges {
        node {
          ...StoryCardFields
        }
      }
    }
    divisions: allSanityDivision(
      filter: { i18n_lang: { eq: $language }, key: { in: $divisionKeys } }
      sort: { fields: priority, order: DESC }
    ) {
      edges {
        node {
          id
          key
          title
          description
          slug {
            current
          }
        }
      }
    }
    allIntroVideos: allSanitySiteSettings {
      edges {
        node {
          i18n_lang
          intro_video {
            title
            preview {
              ...SanityCoverWithBlurredPlaceholderFields
            }
            video_mp4 {
              asset {
                url
              }
            }
            video_webm {
              asset {
                url
              }
            }
          }
        }
      }
    }
  }
`;
