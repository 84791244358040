import React, { Children, isValidElement, cloneElement } from 'react';
import Container from '@/components/Container';

export interface MasonryGridProps {
  staggered?: boolean;
  title?: string;
  children: React.ReactNode;
}

const MasonryGrid: React.FC<
  MasonryGridProps & React.HTMLAttributes<HTMLDivElement>
> = ({ staggered, title, children, ...any }) => (
  <div {...any}>
    {title && (
      <Container>
        <h2 className="text-blue-600 max-w-prose-2xs my-xl-fluid dark:text-blue-500">
          {title}
        </h2>
      </Container>
    )}
    <Container
      role="list"
      aria-label={title}
      className="grid my-xl-fluid gap-base-fluid sm:grid-cols-2 xl:grid-cols-12"
      bleed={true}
    >
      {Children.map(children, (child, i) => {
        const params = { className: `xl:col-span-4`, role: 'listitem' };

        if (staggered) {
          if (i === 0)
            Object.assign(params, {
              className: `sm:col-span-2 xl:col-span-8 xl:row-span-2`,
              large: true,
            });
          else if (i === 1 && Children.count(children) > 8)
            Object.assign(params, {
              className: `sm:col-span-2 xl:col-start-5 xl:row-start-4 xl:col-span-8 xl:row-span-2`,
              large: true,
            });
        }

        if (isValidElement(child)) {
          return cloneElement(child, params);
        }

        return null;
      })}
    </Container>
  </div>
);

export default MasonryGrid;
