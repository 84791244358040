import React from 'react';

const MetalText: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
  ...any
}) => (
  <div
    className={`bg-center text-transparent bg-1.5x bg-clip-text bg-gradient-metal dark:bg-gradient-metal-dark ${
      className ?? ''
    }`}
    {...any}
  >
    {children}
  </div>
);

export default MetalText;
